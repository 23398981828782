import React from "react"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

const LearnMore = () => (
  <div className="learnmore">
    <div className="temp2 layout-foo"></div>
    <div className="temp3 layout-foo"></div>
    <div className="temp5 layout-foo"></div>
    <div className="temp1 layout-foo"></div>
    <div className="layout-foo">Let's try BBA now</div>
    <Link to="/contact">
      <Button className="layout-foo">Learn more</Button>
    </Link>
  </div>
)

export default LearnMore
