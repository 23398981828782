import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const Independent = () => {
  const data = useStaticQuery(graphql`
    query {
      plan6_2: file(relativePath: { eq: "oneStop/plan6_2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      plan6_3: file(relativePath: { eq: "oneStop/plan6_3.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      plan6_4: file(relativePath: { eq: "oneStop/plan6_4.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      plan6_5: file(relativePath: { eq: "oneStop/plan6_5.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }

      plan6_6: file(relativePath: { eq: "oneStop/plan6_6.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      main: file(relativePath: { eq: "oneStop/main.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <>
      {/* 帮你做独立站 */}
      <div className="one-stop-inde-title one-stop-foo">
        You focus on your business, BBA will take care of the rest.
      </div>
      <div className="one-stop-inde">
        <div className="main-img">
          <img
            src={data.main.childImageSharp.fluid.src}
            alt=""
            className="one-stop-foo"
          />
        </div>
        <ul className="one-stop-inde-list">
          <li className="one-stop-inde-webtitle one-stop-foo">
            You focus on your business, BBA will take care of the rest.
          </li>
          <li>
            <svg className="icon one-stop-foo">
              <use href="#iconAdvertisingDelivery" />
            </svg>
            <div className="item-info one-stop-foo">
              <div className="item-info-title">Better Ad Result</div>
              <div className="item-info-text">
                Use our data-driven ad optimization system to boost your
                sales.Our customers love it!
              </div>
            </div>
          </li>
          <li>
            <svg className="icon one-stop-foo">
              <use href="#iconWebsiteMaintenance" />
            </svg>
            <div className="item-info one-stop-foo">
              <div className="item-info-title">Better IT Support</div>
              <div className="item-info-text">
                Find the answers you need from the BBA Community or our
                dedicated support team.
              </div>
            </div>
          </li>
          <li>
            <svg className="icon one-stop-foo">
              <use href="#iconOnlinePayment" />
            </svg>
            <div className="item-info one-stop-foo">
              <div className="item-info-title">
                More Payment Method For Better Conversion Rate
              </div>
              <div className="item-info-text">
                BBA offers support to multiple payment methods which leads to a
                better converting rate of the website.
              </div>
            </div>
          </li>
          <li>
            <svg className="icon one-stop-foo">
              <use href="#iconEffectanalysis" />
            </svg>
            <div className="item-info one-stop-foo">
              <div className="item-info-title">Better Analyze</div>
              <div className="item-info-text">
                View data and reports on your products, orders, visitors to gain
                insights into your business.
              </div>
            </div>
          </li>
        </ul>
      </div>
    </>
  )
}

export default Independent
